@include block("footer") {
  white-space: nowrap;
  a,
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }
  @include element("link") {
    font-size: 15px;
    color: #9f9f9f;
    line-height: 20px;
    display: block;
    max-width: 100px;
    &:hover {
      color: #e5621b;
    }
  }
  @include element("copyright") {
    color: #666666;
    font-size: 12px;
    margin: 0 auto;
  }
  @include element("col") {
    position: relative;
    @include modifier("ceara") {
      font-size: 13px;
      color: #989898;
    }
    @include modifier("rrss") {
      font-size: 20.18px;
      color: #898989;
    }
    @include modifier("links") {
    }
    @include modifier("tels") {
      strong {
        color: #9f9f9f;
        font-size: 17px;
      }
      span {
        font-size: 13px;
        color: #666666;
      }
      a {
        line-height: 13px;
        font-size: 14.57px;
        color: #9f9f9f;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    @include element("col") {
      &:not(:last-of-type) {
        :after {
          display: block;
          width: 1px;
          top: 10%;
          right: 0;
          background-color: #dee2e6;
          height: 80%;
          content: " ";
          position: absolute;
        }
      }
      @include modifier("ceara") {
        max-width: 286px;
      }
      @include modifier("links") {
        max-width: 264px;
      }
      @include modifier("tels") {
        max-width: 210px;
      }
    }
    @include element("link") {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
