%title {
	font-weight: bold;
	font-style: italic;
	font-size: 25px;
	line-height: 1;
	margin: 0;
}
@include block('contact') {
	padding-top: 52px;
	background-size: contain;
	background-position-y: bottom;
	background-repeat: no-repeat;
	background-image: url(../res/img/bg-home-main-content.png);
	padding-bottom: 300px;

	@include element('title') {
		@extend %title;
		color: #353535;
	}
	@include element('subtitle') {
		@extend %title;
		color: #e5621b;
		font-size: 16px;
	}
	@include element('text') {
		color: #989898;
		font-size: 18px;
		line-height: 1;
	}
	@include element('link') {
		color: #9f9f9f;
		font-size: 16px;
		line-height: 1;
		text-decoration: none;
	}
	@include element('pill') {
		padding: 4px 12px;
		font-size: 20px;
		font-weight: bold;
		border-radius: 13px;
		color: #9f9f9f;
		background-color: #ececec;
		display: inline-block;
	}
}
