@include block("filter") {
  @include element("dropdown") {
    font-style: italic;
  }
  @include element("dropdown-toggle") {
    background-color: rgba(#fff, 0.9);
    padding-right: 28px;
    @include border-radius($border-radius-lg * 1.5);
    &::after {
      border: none;
      height: 24px;
      width: 24px;
      position: absolute;
      right: 4px;
      color: #e5621b;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e5621b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    }
  }
  @include modifier("") {
  }
  @include state("") {
  }
}
