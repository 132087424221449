%btn {
    @include border-radius($border-radius-lg * 1.7);
    background-color: #e5621b;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
}
%btn:hover,
%btn:focus {
    text-decoration: none;
    color: #fff;
    animation: heartbeat 1.5s ease-in-out infinite both;
}
%title {
    font-weight: bold;
    font-style: italic;
    font-size: 25px;
    line-height: 1;
    margin: 0;
}
@include block("details") {
    padding-top: 52px;
    background-size: contain;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-image: url(../res/img/bg-home-main-content.png);

    @include element("title") {
        @extend %title;
        color: #353535;
    }
    @include element("subtitle") {
        @extend %title;
        color: #e5621b;
    }
    @include element("feature") {
        color: #343434;
        font-size: 13px;
        font-weight: 700;
        padding: 0.5rem;
        display: flex;
        min-width: 60px;
        text-align: center;
        flex-direction: column;
        justify-content: space-between;
        text-transform: uppercase;
        max-width: 100px;
        line-height: 1.23;
        img{
            margin-bottom: 0.5rem;
        }
    }
    @include element("features-list") {
        display: flex;
        list-style: none;
        margin: 0 $spacer * -0.5;
        padding: 0;
    }
    @include element("itinerary-btn") {
        @extend %btn;
        display: inline-block;
        background-image: url(../res/img/details__itinerary-btn.png);
        background-repeat: no-repeat;
        background-position: $spacer * 0.6 $spacer * 0.4;
        padding: $spacer * 0.4 $spacer $spacer * 0.4 $spacer * 2.8;
    }
    @include element("booking-btn") {
        @extend %btn;
        text-align: left;
        white-space: nowrap;
        display: inline-block;
        background-image: url(../res/img/details__booking-btn.png);
        background-repeat: no-repeat;
        background-position: $spacer * 0.6 $spacer * 0.4;
        line-height: 1;
        @include border-radius($border-radius-lg * 2.5);
        padding: $spacer * 0.7 $spacer $spacer * 0.7 $spacer * 3.5;
    }
    @include element("txt") {
        font-style: italic;
        font-size: 17px;
        color: #535353;
    }
    @include element("extra") {
        font-style: italic;
        font-size: 17px;
        color: #535353;
    }
    @include element("extra-list") {
        font-style: italic;
        font-size: 17px;
        color: #535353;
        line-height: 1.1;
        margin: $spacer 0;
        @include modifier("si") {
            list-style-image: url(../res/img/details__extra-list--si.png);
        }
        @include modifier("no") {
            list-style-image: url(../res/img/details__extra-list--no.png);
        }
    }
    @include element("info") {
        font-style: italic;
        font-size: 13px;
        color: #8d8d8d;
    }
    @include element("lastitle") {
        @extend %title;
        color: #353535;
        font-size: 21px;
    }

    @include element("table") {
        white-space: nowrap;
        * {
            user-select: none;
        }
    }
    @include element("table-pack") {
        color: #373737;
        font-style: italic;
        font-size: 17px;
        display: block;
        line-height: 1.1;
    }
    @include element("table-em") {
        color: #e5621b;
        font-style: italic;
        font-size: 17px;
        display: block;
        line-height: 1.1;
    }
    @include element("table-price") {
        color: #161616;
        font-style: italic;
        font-size: 36px;
        font-weight: bold;
        line-height: 1.1;
        vertical-align: middle;
        text-align: center;
        small {
            font-size: 24px;
        }
    }
    @include element("table-th") {
        color: #161616;
        font-style: italic;
        line-height: 1.1;
        font-size: 15px;
        vertical-align: middle;
        text-align: center;
        padding: $spacer !important;
        &:not(:first-of-type) {
            text-align: center;
        }
    }
}

@include block("cover") {
    position: relative;
    @include element("img") {
        display: block;
        max-width: 100%;
        width: 100%;
        @include border-radius($border-radius-lg * 1.5);
        overflow: hidden;
    }
    @include element("badge") {
        position: absolute;
        left: 60px;
        top: -32px;
    }
}

@include block("ask-form") {
    @include border-radius($border-radius-lg * 1.5);
    background: #f3f3f3;
    position: relative;
    padding: 132px $spacer $spacer;
    width: 390px;
    max-width: 100%;
    &::before {
        position: absolute;
        display: block;
        content: "";
        background-image: url(../res/img/ask-form__badge.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 124px;
        height: 99px;
        top: -$spacer * 0.5;
        left: $spacer;
    }
    @include element("input") {
        margin-bottom: $spacer * 0.6;
        input {
            padding: $spacer;
            @include border-radius($border-radius-lg * 1.5);
            background-color: #fff;
            border: none;
            width: 100%;
            height: 52px;
        }
    }
    @include element("submit") {
        display: block;
        height: 52px;
        font-size: 18px;
        line-height: 52px;
        text-align: center;
        @extend %btn;
        border: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    @include media-breakpoint-up(lg) {
        top: $spacer * -3;
    }
}

@include block("pricing") {
    position: absolute;
    right: $spacer;
    top: $spacer;
    @include element("before") {
        color: #e5621b;
        font-style: italic;
        font-size: 18px;
        position: absolute;
        left: 0;
        top: 0;
    }
    @include element("after") {
        color: #343434;
        font-size: 18px;
        font-style: italic;
        position: absolute;
        left: 0;
        bottom: -2px;
    }
    @include element("price") {
        font-size: 56px;
        font-style: italic;
        font-weight: bold;
        small {
            font-size: 34px;
        }
    }
}
@keyframes heartbeat {
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
    }
    10% {
        transform: scale(0.91);
        animation-timing-function: ease-in;
    }
    17% {
        transform: scale(0.98);
        animation-timing-function: ease-out;
    }
    33% {
        transform: scale(0.87);
        animation-timing-function: ease-in;
    }
    45% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
}
