@include block('departures') {
	@include element('title') {
		color: #353535;
		font-style: italic;
		font-size: 20px;
		line-height: 1;
		font-weight: bold;
	}
	@include element('aside') {
		width: 212px;
		&-title {
			display: block;
			color: #e5621b;
			font-style: italic;
			font-size: 20px;
			line-height: 1;
			font-weight: bold;
		}
	}
	@include element('accordion-toggle') {
		text-decoration: none;
		color: #979696;
		font-style: italic;
		font-size: 20px;
		line-height: 1;
		background-color: rgba(0, 0, 0, 0);
		border: none;
		padding: $spacer * 0.25;
	}
	@include element('select-all') {
		display: flex;
		text-decoration: none;
		color: #979696;
		font-style: italic;
		font-size: 20px;
		line-height: 1;
	}
	@include element('filter-link') {
		text-decoration: none;
		color: #979696;
		font-style: italic;
		font-size: 16px;
		display: block;
		padding: $spacer * 0.25 $spacer * 0.5;
		line-height: 1;
		margin-left: $spacer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@include border-radius($border-radius-lg);
		&.selected,
		&:hover {
			background-color: #e5621b;
			color: #fff;
		}
	}
}
