.navbar {
	text-decoration: none;
	z-index: 2;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	a {
		text-decoration: none;
	}
	&-tels {
		margin-bottom: 16px;
		img {
			margin-right: 4px;
			margin-left: 4px;
		}
		&,
		* {
			font-family: Barlow;
			font-style: italic;
			font-size: 16px;
			color: #41464b;
		}
		a{
			white-space: nowrap;
		}
	}
	&-wrap {
		// height: 625px;
		// position: fixed;
		position: relative;
		z-index: 9;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		// overflow: hidden;
	}
	&-link {
		padding-left: 28px;
		padding-right: 28px;
		padding-top: 8px;
		padding-bottom: 6px;
		display: inline-block;
		font-family: Barlow;
		font-weight: bold;
		color: #41464b;
		white-space: nowrap;
		&__active,
		&:hover,
		&.dropdown-toggle.show {
			color: #fff;
			@include border-radius();
			background-color: #e5621b;
		}
		&_em {
			white-space: nowrap;
			color: #e5621b !important;
			background-color: #f0f0f0;
			border-radius: $border-radius-lg;
			// @include border-radius($border-radius-lg);
			span {
				font-style: italic;
				color: #aaa;
			}

			&:hover {
				&, & span {
					color: #fff !important;
				}
			}
		}
	}
	&-carousel {
		z-index: 1;
		// position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		.swiper {
			height: 625px;
			&-slide {
				overflow: hidden;
				img {
					display: block;
					width: 100%;
				}
			}
		}
	}
	&-form {
		position: relative;
		z-index: 2;
		margin-bottom: 32px;
		&-btn {
			@include border-radius($border-radius-lg * 1.5);
			background-color: #e5621b;
			width: 150px;
			display: block;
			border: none;
			text-align: center;
			padding-top: 8px;
			padding-bottom: 7px;
			text-decoration: none;
			font-weight: bold;
			font-size: 17px;
			color: #fff;
		}
		.dropdown-toggle {
			width: 100%;
			text-align: left;
		}
	}
	&-txt {
		position: relative;
		z-index: 2;
		font-size: 32px;
		color: #fff;
		text-align: center;
		font-style: italic;
		line-height: 1;
	}
	&-pre{
		color: #fff;
		z-index: 99;
		position: relative;
		background-color: #e5621b;
		font-style: italic;
		&-link{
			padding: 4px;
			color: #fff;
			text-decoration: none;
			font-size: 16px;
			line-height: 1;
			svg{
				display: inline-block;
			}
			&:hover{
				color: #fff;
			}
		}
	}
	&-bg{
		background-color: #fff;
		position: relative;
		z-index: 9;
	}

	.dropdown-menu {
		border: none;
		@include border-radius($border-radius-lg);
		margin-top: $dropdown-spacer * 2;
	}
	.dropdown-toggle {
		&::after {
			display: none;
		}
	}
	.dropdown-item {
		position: relative;
		padding-bottom: 8px;
		padding-top: 8px;

		&:hover,
		&:focus {
			background-color: #e1e1e1;
		}
		&:not(:last-of-type):not(:hover) {
			&::after {
				content: ' ';
				display: block;
				position: absolute;
				background-color: #e1e1e1;
				height: 1px;
				left: 16px;
				right: 16px;
				bottom: -1px;
			}
		}
	}
	&-dropdown-link {
		color: #000 !important;
	}
}

@include block('navbar-carousel') {
	@include element('img') {
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}
