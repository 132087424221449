.form-check-input {
    &:checked {
        background-color: #f87518;
        border-color: #e5621b;
    }
    &:focus {
        border-color: #eba179;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(255 152 0 / 23%);
    }
}
