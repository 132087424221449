@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bemify";

* {
    font-family: "Barlow", sans-serif;
}

body{
    min-width: 320px;
    @media screen and (max-width: 321px) {
        overflow-x: hidden;
    }
}

@import "_bootstrap";

@import "components/form";
@import "components/navbar";
@import "components/card";
@import "components/filter";
@import "components/footer";
@import "components/hotels";

@import "pages/home";
@import "pages/details";
@import "pages/departures";
@import "pages/contact";
