@include block('home-departures') {
	padding-top: 24px;
	padding-bottom: 24px;
	background-image: url(../res/img/bg-home-main-content.png);
	background-size: cover;
	background-position-y: bottom;
	background-repeat: no-repeat;
}

@include block('section') {
	@include element('title') {
		font-size: 26px;
		font-family: Barlow;
		font-style: italic;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
		a {
			white-space: nowrap;
			font-weight: bold;
			font-style: italic;
			position: relative;
			color: #7b7b7b;
			text-decoration: none;
			font-size: 1.1rem;
			&:hover{
				color: #e5621b;
			}
		}
		hr {
			background-color: #e0e0e0;
			width: 100%;
			margin-left: 2rem;
			margin-right: 2rem;
		}
	}
}

@include block('home-videos') {
	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 75px;
	padding-bottom: 75px;
	background-image: url(../res/img/bg-home-videos.png);
	background-size: cover;
	background-position-y: bottom;
	background-repeat: no-repeat;
	@include element('title') {
		font-size: 35px;
		font-weight: normal;
		letter-spacing: -1.5px;
		color: #fff;
		font-style: italic;
		margin-bottom: 28px;
		text-align: center;
		em {
			color: #d8dd56;
		}
	}
	@include element('item') {
		max-width: 100%;
		padding: 12px;
	}
	@include modifier('sm') {
	}
	@include state('') {
	}
}

@include block('home-gallery') {
	@include element('slide') {
		display: block;
		height: 140px !important;
		width: 196px !important;
	}
	@include element('img') {
		display: block;
		height: 140px !important;
		width: 196px !important;
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}

.wa-link {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 9999;
	&:hover {
		animation: heartbeat 1.5s ease-in-out infinite both;
	}
}
