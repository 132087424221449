@include block('card') {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
	height: $card-height;
	word-wrap: break-word;
	background-color: $card-bg;
	background-clip: border-box;
	border: none;
	padding: 12px;
	margin-bottom: 32px;
	@include border-radius($card-border-radius);
	box-shadow: $box-shadow-sm;
	text-decoration: none;

	@include element('img') {
		margin-bottom: 12px;
		img {
			display: block;
			max-width: 100%;
			width: 100%;
		}
	}

	@include element('foot') {
		display: flex;
		height: 48px;
	}

	@include element('promo') {
		position: absolute;
		left: 20px;
		top: 20px;
		max-width: 100px !important;
	}

	@include element('share') {
		display: block;
		align-self: center;
	}

	@include element('title') {
		padding-right: 16px;
		font-weight: bold;
		font-style: italic;
		align-self: center;
		margin: 0;
		width: 100%;
		color: #4a4a4a;
		font-size: 23px;
		line-height: 23.88px;
	}

	@include element('pricing') {
		white-space: nowrap;
		font-style: italic;
		color: #999999;
		line-height: 1;
		font-size: 15.92px;
		line-height: 19.04px;
	}

	@include element('price') {
		font-size: 23.8px;
		color: #e5621b;
		font-weight: bold;
	}

	@include element('list') {
		position: absolute;
		background-color: #fff;
		list-style: none;
		margin: 0;
		padding: 0;
		top: -6px;
		right: 17px;
		text-align: center;
		@include child('item') {
			width: 50px;
			display: block;
			&:first-of-type {
				position: relative;
				margin-bottom: 12px;
				padding-top: 9px;
				background-color: #27e1da;
				border-top-left-radius: $border-radius;
				border-top-right-radius: $border-radius;
				span {
					font-size: 12px;
					line-height: 1;
					display: block;
					color: #fff;
				}
				&::after {
					position: absolute;
					top: 100%;
					left: 0;
					width: 0;
					height: 0;
					content: '';
					z-index: 2;
					border-style: solid;
					border-width: 11px 25px 0 25px;
					border-color: #27e1da transparent transparent transparent;
					border-width: 11px 25px 0 25px;
					border-top-left-radius: $border-radius;
				}
			}
		}
		&::after {
			position: absolute;
			top: 100%;
			left: 0;
			width: 0;
			height: 0;
			content: '';
			z-index: 2;
			border-style: solid;
			border-width: 11px 25px 0 25px;
			border-color: #fff transparent transparent transparent;
			border-width: 11px 25px 0 25px;
		}
	}

	@include element('link') {
		font-weight: bold;
		font-style: italic;
		position: relative;
		margin-left: 20px;
		color: #7b7b7b;
		text-decoration: none;
		&::after {
			content: ' ';
			background-color: #999999;
			display: block;
			height: 19px;
			width: 1px;
			position: absolute;
			top: 0;
			left: -10px;
		}
		&:hover {
			color: #e5621b;
			text-decoration: none;
		}
	}
}
