%hotels_common {
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
  display: block;
}
%hotels_title {
  width: 275px;
}
%hotels_categ {
  width: 45px;
}
%hotels_reg {
  width: 125px;
}
%hotels_hab {
  width: 80px;
}
%hotels_sgl {
  width: 88px;
}
%hotels_dbl {
  width: 88px;
}
%hotels_tpl {
  width: 88px;
}
%hotels_qpl {
  width: 88px;
}
%hotels_vigency {
  width: 110px;
}

.hotels-th {
  height: 41px;
  display: flex;
  align-items: center;
  color: #8b8b8b;
  background-color: #f6f6f6;
  font-size: 15px;
  font-weight: bold;
  font-style: italic;
  width: 1084px;
  @include border-radius($border-radius-lg);
  margin-bottom: 9px;
  &__title {
    @extend %hotels_common;
    @extend %hotels_title;
  }
  &__categ {
    @extend %hotels_common;
    @extend %hotels_categ;
    padding: 0;
  }
  &__reg {
    @extend %hotels_common;
    @extend %hotels_reg;
  }
  &__hab {
    @extend %hotels_common;
    @extend %hotels_hab;
  }
  &__sgl {
    @extend %hotels_common;
    @extend %hotels_sgl;
  }
  &__dbl {
    @extend %hotels_common;
    @extend %hotels_dbl;
  }
  &__tpl {
    @extend %hotels_common;
    @extend %hotels_tpl;
  }
  &__qpl {
    @extend %hotels_common;
    @extend %hotels_qpl;
  }
  &__vigency {
    @extend %hotels_common;
    @extend %hotels_vigency;
  }
}
.hotel {
  border: 1px solid #dee2e6 !important;
  margin-bottom: 9px;
  @include border-radius($border-radius-lg);
  font-family: Barlow;
  display: flex;
  height: 67px;
  align-items: center;
  width: 1084px;
  &__title {
    @extend %hotels_common;
    @extend %hotels_title;
    font-weight: bold;
    font-size: 17px;
    color: #e5621b;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    text-align: left;
  }
  &__categ {
    @extend %hotels_common;
    @extend %hotels_categ;
    padding: 0;
    font-style: italic;
  }
  &__reg {
    @extend %hotels_common;
    @extend %hotels_reg;
    color: #373737;
    font-style: italic;
    font-size: 17px;
    line-height: 18px;
    max-height: 18px * 2;
    overflow: hidden;
    font-weight: normal;
    text-transform: uppercase;
  }
  &__hab {
    @extend %hotels_common;
    @extend %hotels_hab;
  }
  &__sgl {
    @extend %hotels_common;
    @extend %hotels_sgl;
    color: #373737;
    font-weight: bold;
    font-size: 17px;
  }
  &__dbl {
    @extend %hotels_common;
    @extend %hotels_dbl;
    color: #373737;
    font-weight: bold;
    font-size: 17px;
  }
  &__tpl {
    @extend %hotels_common;
    @extend %hotels_tpl;
    color: #373737;
    font-weight: bold;
    font-size: 17px;
  }
  &__qpl {
    @extend %hotels_common;
    @extend %hotels_qpl;
    color: #373737;
    font-weight: bold;
    font-size: 17px;
  }
  &__vigency {
    @extend %hotels_common;
    @extend %hotels_vigency;
    font-style: italic;
    font-size: 17px;
    color: #373737;
    font-weight: normal;
    padding: 0;
    line-height: 1;
  }
  &__ctas {
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
  }
  &__cta {
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    display: block;
    &:not(:last-of-type):after {
      content: "";
      position: absolute;
      top: 20%;
      left: 100%;
      height: 60%;
      display: block;
      width: 1px;
      background-color: #000;
    }
  }
}
